<template>
  <transition name="fade">
    <div class="message-box">
      <div class="content-box">
        <img class="success-img" :src="successUrl" alt="" />
        <p class="result-title">您的课题已提交成功</p>
        <p class="result-msg">
          课题ID <span class="class-id">{{ message || 'dddd' }}</span
          >,我们将仔细阅读您的课题,尽快完成评审工作，及时与您联系！请注意查收邮箱或
          <span @click="jumpToHome">首页</span>查询课题状态
        </p>
        <div class="operate-btn">
          <!-- <button class="cancel" @click="jumpToHome">取消</button> -->
          <button class="confirm" @click="jumpToHome"> 确认</button>
        </div>
      </div>
    </div>

  </transition>
</template>

<script>
export default {

  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      successUrl: require('@/assets/success-icon.svg'),
      show: false,
    }
  },
  methods: {
    jumpToHome () {
      this.$router.push('/classes#process')
    },

  },
  mounted () {},
}
</script>

<style scoped>
.message-box {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 358px;
  padding: 0px 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: HarmonyOS Sans SC;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.content-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  line-height: 30px;
}
.result-msg {
  margin-top: 20px;
  padding: 0 60px;
  text-align: center;
  .class-id {
    color: #ff0000;
  }
  .router {
    color: #0057ff;
  }
}
.result-title{
  margin-top: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}
.operate-btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  button {
    width: 100px;
    height: 44px;
    border-radius: 5px;
    font-size: 16px;
  }
  .cancel {
    background: #fff;
    border: 1px solid #ccc;
    color: #0057ff;

  }
  .confirm{
    background: #0057ff;
    color: #fff;
    border: none;
  }
}
.success-img {
  width: 54px;
  height: 54px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.fail {
  background: #fdebeb;
}
</style>
